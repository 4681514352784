import home from './../views/Pages/Home/text.json';
import transactions from './../views/Pages/Transactions/text.json';
import bills from './../views/Pages/BillsTransactions/text.json';
import invoice from './../views/Pages/Invoice/text.json';
import jawaalInvoice from './../views/Pages/Invoice/jawaalText.json';
import PaymentIntegrations from './../views/Pages/PaymentIntegrations/text.json';
import Paypal_callback from './../views/Pages/PaymentIntegrations/Paypal_callback/text.json';
import tutorials from './../views/Pages/tutorials/text.json';
import iframes from './../views/Pages/iframes/text.json';
import products from './../views/Pages/Products/text.json';
import Products_create from './../views/Pages/Products_create/text.json';
import users from './../views/Pages/Users/text.json';
import customers from './../views/Pages/Customers/text.json';
import settings from './../views/Pages/settings/text.json';
import orders from './../views/Pages/orders/text.json';
import transfers from './../views/Pages/Transfers/text.json';
import transactions_bs from './../views/Pages/bs/Transactions_bs/text.json';
import reports_bs from './../views/Pages/bs/Reports_bs/text.json';
import merchants from './../views/Pages/bs/Merchants_bs/text.json'
import requestToPay from './../views/Pages/requestToPay/text.json'
import Popup from './../views/Pages/PopUp/text.json';
import reports from './../views/Pages/Reports/text.json';
import invoicePage from './../views/Pages/invoicePage/text.json';
import paymentLinksPage from './../views/Pages/paymentLinksPage/text.json';
import Invoice_create from './../views/Pages/Invoice_create/text.json';
import chargeBacks from './../views/Pages/chargeBacks/text.json';
import bulkInvoice from './../views/Pages/bulkInvoice/text.json';
import reportPOS from './../views/Pages/reportsPos/text.json';
import devices from './../views/Pages/devicesPOS/text.json';
import branch from './../views/Pages/branches/text.json';
import qrCode from './../views/Pages/QRCode/text.json';

import transactions_terminal from './../views/Pages/terminals_portal/Transactions/text.json';
import Flash_Integration from '../views/Pages/Flash/Integration/text.json';
import Flash from '../views/Pages/Flash/Integration/flash_text.json';
import Flash_Test_Integration from './../views/Pages/Flash/Tutorials/Flash_Test_Integration/text.json';
import Flash_Live_Integration from './../views/Pages/Flash/Tutorials/Flash_Live_Integration/text.json';
import Flash_Intentions from './../views/Pages/Flash/Intentions/text.json';
import Flash_Customers from './../views/Pages/Flash/Customers/text.json';
import Flash_Confirmations from './../views/Pages/Flash/Confirmations/text.json';
import Flash_Payment_Methods from './../views/Pages/Flash/PaymentMethods/text.json';
import Flash_Tutorials from './../views/Pages/Flash/Tutorials/text.json';
import createPaymentLink from './../views/Pages/CreatePaymentLink/text.json';
import LendingPage from './../views/Pages/lending/text.json';
import PayoutPage from './../views/Pages/Payouts/text.json';
import RiskCenter from '../views/Pages/RiskCenter/text.json';
import Statements from './../views/Pages/Statements/text.json';

let localText = {
    "en": {
        "home": {
            ...home.en
        },
        "transactions": {
            ...transactions.en
        },
        "bills": {
            ...bills.en
        },
        "chargeBacks": {
            ...chargeBacks.en
        },
        "invoice": {
            ...invoice.en
        },
        'bulkInvoice': {
            ...bulkInvoice.en
        },
        "PaymentIntegrations": {
            ...PaymentIntegrations.en
        },
        "Paypal_callback": {
            ...Paypal_callback.en
        },
        "tutorials": {
            ...tutorials.en
        },
        "branch": {
            ...branch.en
        },
        "reportPOS": {
            ...reportPOS.en
        },
        "devices": {
            ...devices.en
        },
        "reports": {
            ...reports.en
        },
        "iframes": {
            ...iframes.en
        },
        "products": {
            ...products.en
        },
        "Products_create": {
            ...Products_create.en
        },
        "riskCenter":{
          ...RiskCenter.en
        },
        "statements":{
          ...Statements.en
        },
        "users": {
            ...users.en
        },
        "customers": {
            ...customers.en
        },
        "settings": {
            ...settings.en
        },
        "orders": {
            ...orders.en
        },
        "transfers": {
            ...transfers.en
        },
        "requestToPay": {
            ...requestToPay.en
        },
        "invoicePage": {
            ...invoicePage.en
        },
        "paymentLinksPage": {
            ...paymentLinksPage.en
        },
        "Invoice_create": {
            ...Invoice_create.en
        },
        "qrCode": {
            ...qrCode.en
        },
        "Popup": {
            ...Popup.en
        },
        "DefaultHeader": {
            "merchantId": "Merchant ID: ",
            "flag": "flag-icon flag-icon-gb",
            "flagText": "EN",
            "create": "Create",
            "activateAccount": "Activate Account",
            "createPaymentLink": "Quick Link",
            "product": "Product",
            "invoice": "Invoice",
            "bulkInovoice": "Bulk Invoices",
            "logoutMessage": "You have logged-out successfully",
            "qrCode": "QR Payment",
            "sideMenu": {
                "editProfile": "Edit Profile",
                "settings": "Settings",
                "tutorials": "Tutorials",
                "logout": "Log Out"
            },
            "profileUpdate": {
                "profileModal": {
                    "title": "Company Info",
                    "cancel": "Cancel",
                    "edit": "Edit",
                    "back": "Back",
                    "confirm": "Confirm",
                    "name": "Company name",
                    "phones": "Contact Numbers",
                    "emails": "Emails",
                    "addPhone": "Add Phone",
                    "addEmail": "Add Email",
                },
                "logoModal": {
                    "title": "Upload Logo",
                    "cancel": "Cancel",
                    "confirm": "Confirm",
                    "success": "Your request completed successfully.",
                },
                "success": "Your request completed successfully.",
                "networkError": "Oops! Something went wrong. If this problem persists, please contact your service provider.",
            }
        },
        "welcome": "Welcome",
        "showQuestionHeader": "Tell us more about yourself",
        "showQuestionBtn": "Start",
        "SideMenuFooter": {
            "setting": "Setting",
            "refresh": "Refresh Page",
            "docs": "Go to Documentation",
            "logout": "Logout"
        },
        // bank stafe
        "transactions_bs": {
            ...transactions_bs.en
        },
        "merchants": {
            ...merchants.en
        },
        "reports_bs": {
            ...reports_bs.en
        },
        //terminals
        "transactions_terminal": {
            ...transactions_terminal.en
        },
        "Flash_Integration": {
            ...Flash_Integration.en
        },
        "Flash": {
            ...Flash.en
        },
        "Flash_Test_Integration": {
            ...Flash_Test_Integration.en
        },
        "Flash_Live_Integration": {
            ...Flash_Live_Integration.en
        },
        "Flash_Intentions": {
            ...Flash_Intentions.en
        },
        "Flash_Customers": {
            ...Flash_Customers.en
        },
        "Flash_Confirmations": {
            ...Flash_Confirmations.en
        },
        "Flash_Payment_Methods": {
            ...Flash_Payment_Methods.en
        },
        "Flash_Tutorials": {
            ...Flash_Tutorials.en
        },
        "createPaymentLink": {
            ...createPaymentLink.en
        },
        "lendingPage" : {
            ...LendingPage.en
        },
        "payout": {
            ...PayoutPage.en
        }
    },
    "ar": {
        "home": {
            ...home.ar
        },
        "transactions": {
            ...transactions.ar
        },
        "bills": {
            ...bills.ar
        },
        "chargeBacks": {
            ...chargeBacks.ar
        },
        "invoice": {
            ...invoice.ar
        },
        'bulkInvoice': {
            ...bulkInvoice.ar
        },
        "PaymentIntegrations": {
            ...PaymentIntegrations.ar
        },
        "Paypal_callback": {
            ...Paypal_callback.ar
        },
        "tutorials": {
            ...tutorials.ar
        },
        "reports": {
            ...reports.ar
        },
        "iframes": {
            ...iframes.ar
        },
        "products": {
            ...products.ar
        },
        "Products_create": {
            ...Products_create.ar
        },
        "riskCenter":{
            ...RiskCenter.ar
        },
        "statements":{
          ...Statements.ar
        },
        "users": {
            ...users.ar
        },
        "branch": {
            ...branch.ar
        },
        "customers": {
            ...customers.ar
        },
        "settings": {
            ...settings.ar
        },
        "orders": {
            ...orders.ar
        },
        "transfers": {
            ...transfers.ar
        },
        "requestToPay": {
            ...requestToPay.ar
        },
        "invoicePage": {
            ...invoicePage.ar
        },
        "paymentLinksPage": {
            ...paymentLinksPage.ar
        },
        "Invoice_create": {
            ...Invoice_create.ar
        },
        "reportPOS": {
            ...reportPOS.ar
        },
        "devices": {
            ...devices.ar
        },
        "qrCode": {
            ...qrCode.ar
        },
        "Popup": {
            ...Popup.ar
        },
        "DefaultHeader": {
            "merchantId": 'الرقم التجاري: ',
            "flag": "flag-icon flag-icon-eg",
            "flagText": "AR",
            "create": "إنشاء",
            "activateAccount": "تفعيل الحساب",
            "createPaymentLink": "رابط دفع سريع",
            "product": "منتج",
            "invoice": "فاتورة",
            "bulkInovoice": "فواتير مجمعه",
            "logoutMessage": "تم تسجيل الخروج بنجاح",
            "qrCode": "آنشاء الرمز",
            "sideMenu": {
                "editProfile": "تعديل البيانات",
                "settings": "الإعدادات",
                "tutorials": "دروس تعليمية",
                "logout": "تسجيل خروج"
            },
            "profileUpdate": {
                "profileModal": {
                    "title": "بيانات الشركة",
                    "cancel": "إلغاء",
                    "edit": "تعديل",
                    "back": "رجوع",
                    "confirm": "تأكيد",
                    "name": "اسم الشركة",
                    "phones": "ارقام الهاتف",
                    "emails": "البريد الإلكترونى",
                    "addPhone": "إضافة هاتف",
                    "addEmail": "إضافة بريد",
                },
                "logoModal": {
                    "title": "تحديث الشعار",
                    "cancel": "إلغاء",
                    "confirm": "تأكيد",
                    "success": "تم تنفيذ طلبك بنجاح.",
                },
                "success": "تم تنفيذ طلبك بنجاح.",
                "networkError": "حدث خطأ اثناء الارسال ، اذا استمرت المشكلة برجاء الإتصال بمزود الخدمة.",
            }
        },
        "welcome": "أهلًا بك",
        "showQuestionHeader": "اخبرنا اكثر عن نفسك",
        "showQuestionBtn": "ابدآ",

        "SideMenuFooter": {
            "setting": "الإعدادات",
            "refresh": "تحديث الصفحة",
            "docs": "الذهاب إلى الوثائق",
            "logout": "تسجيل خروج"
        },
        // bank stafe
        "transactions_bs": {
            ...transactions_bs.ar
        },
        "merchants": {
            ...merchants.ar
        },
        "reports_bs": {
            ...reports_bs.ar
        },
        //terminals
        "transactions_terminal": {
            ...transactions_terminal.ar
        },
        "Flash_Integration": {
            ...Flash_Integration.ar
        },
        "Flash": {
            ...Flash.ar
        },
        "Flash_Test_Integration": {
            ...Flash_Test_Integration.ar
        },
        "Flash_Live_Integration": {
            ...Flash_Live_Integration.ar
        },
        "Flash_Intentions": {
            ...Flash_Intentions.ar
        },
        "Flash_Customers": {
            ...Flash_Customers.ar
        },
        "Flash_Confirmations": {
            ...Flash_Confirmations.ar
        },
        "Flash_Payment_Methods": {
            ...Flash_Payment_Methods.ar
        },
        "Flash_Tutorials": {
            ...Flash_Tutorials.ar
        },
        "createPaymentLink": {
            ...createPaymentLink.ar
        },
        "lendingPage" : {
            ...LendingPage.ar
        },
        "payout": {
            ...PayoutPage.ar
        }
    }
}

const domain_name = window.location.hostname;
if (domain_name === 'checkout.jawwalpay.ps') {
    localText["en"]["invoice"] = { ...jawaalInvoice.en }
    localText["ar"]["invoice"] = { ...jawaalInvoice.ar }
}
export default localText;