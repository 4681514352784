import { getFromLocalStorage } from "./localStorageHandlers";
import text from "../Dependencies.json";

export default function permsName(perm) {
	const Text = text[getFromLocalStorage("lang")];
  if (getFromLocalStorage("lang") === "ar") {
    if (Text.perms[perm]) {
      return Text.perms[perm];
    } else {
      return perm;
    }
  } else {
    return perm;
  }
}
