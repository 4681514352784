import { getFromLocalStorage } from "./localStorageHandlers";

export default function getAccountPermNames(list_of_perm_ids) {
  let account_perms = [];
  if (getFromLocalStorage("permNames")) {
    let keys = getFromLocalStorage("permsDict");
    keys = JSON.parse(keys);
    for (let i = 0; i < list_of_perm_ids.length; i++) {
      let perm_id = list_of_perm_ids[i];
      let perm_name = keys[perm_id];
      account_perms.push(perm_name);
    }
  }
  return account_perms;
}
